import React, { useState, useEffect } from "react";
import { Table, Card, Tag } from "antd";
import { getSafety } from "../services/penalty";
import { mapType } from "../common/Utils";
import type { ColumnsType } from "antd/es/table";
import { ReferenceCell } from "../components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
function SafetyPenaltiesPage() {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSafety();
        setData(response.data);
      } catch (error) {
        console.error("Error fetching safety penalties:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "Referenza",
      key: "sennder_reference",
      render: ReferenceCell,
      width: 160,
      sorter: (a, b) => {
        const value1 = a.sennder_reference || "";
        const value2 = b.sennder_reference || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Nodo",
      key: "tour_composition",
      render: (data) => <p>{data.route_composition || "-"}</p>,
      width: 100,
      sorter: (a, b) => {
        const value1 = a.route_composition || "";
        const value2 = b.route_composition || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "ASSENZA DOTAZIONI",
      key: "delay_type",
      render: (data) => (
        <p>
          {data.processing_category || mapType(data.processing_category || "-")}
        </p>
      ),
      width: 100,
      sorter: (a, b) => {
        const value1 = a.processing_category || "";
        const value2 = b.processing_category || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Tipo",
      key: "processing_category",
      render: (data) => <p>Safety</p>,
      width: 120,
      sorter: (a, b) => {
        const value1 = a.delay_type || "";
        const value2 = b.delay_type || "";
        return value1.localeCompare(value2);
      },
    },
    {
      title: "Stato",
      key: "status",
      render: (data) => (
        <Tag color={"red"} icon={<ExclamationCircleOutlined />}>
          APPLICATA
        </Tag>
      ),
      width: 200,
    },
  ];

  return (
    <div className="content-wrapper">
      <Card className="table-card">
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{ x: "max-content", y: "50vh" }}
        />
      </Card>
    </div>
  );
}

export default SafetyPenaltiesPage;
