import axios from "axios";
import {
  IPenalty,
  PenaltyAttachmentType,
  PenaltyCategory,
} from "./penalty.model";
import Cookies from "js-cookie";
import { PenaltyFilters } from "../../common/interfaces";

export const getSubmitted = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["NOT_APPLIED", "APPLIED", "BILLED", "TO_REVIEW"];
  const admittedDelayTypes = ["Transit", "Positioning", "No Show", "Departure"];

  const status = filters?.status || defaultStatus.join(",");
  const delay_type = admittedDelayTypes.join(",");
  const params = {
    ...filters,
    delay_type,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const getSafety = async () => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/penalties?delay_type=safety`,
    {
      headers: {
        authorization: Cookies.get("_auth"),
      },
    }
  );
};

export const getPending = async (filters?: PenaltyFilters) => {
  const defaultStatus = ["PENDING"];

  const status = filters?.status || defaultStatus.join(",");
  const params = {
    ...filters,
    status,
  };

  return axios.get(`${process.env.REACT_APP_API_URL}/penalties`, {
    params,
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalties = async (penalties: IPenalty[]) => {
  const body = { penalties };
  return axios.put(`${process.env.REACT_APP_API_URL}/penalties/`, body, {
    headers: {
      authorization: Cookies.get("_auth"),
    },
  });
};

export const updatePenalty = async (
  id: string,
  penalty: IPenalty,
  attachments: any
) => {
  const formData = new FormData();

  attachments.forEach((attachment: any) => {
    const file = attachment.file.originFileObj;
    if (file instanceof File) {
      formData.append(`attachment_${attachment.attachment_type}`, file);
    }
  });

  formData.append("penalty", JSON.stringify(penalty));

  return axios.put(
    `${process.env.REACT_APP_API_URL}/penalties/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const uploadAttachments = async (id: any, attachments: any) => {
  const formData = new FormData();

  for (let attachment of attachments) {
    const file = attachment.originFileObj;
    if (file instanceof File) {
      formData.append("attachment", file);
    }
  }

  return axios.post(
    `${process.env.REACT_APP_API_URL}/penalties/upload-attachments/${id}`,
    formData,
    {
      headers: {
        authorization: Cookies.get("_auth"),
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

/**
 * API function to delete submitted attachments
 * DELETE api/attachments?id={attachment_id},{attachment_id}
 */
export const deleteSubmittedAttachment = async (id: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/attachments`, {
      params: { id },
      headers: {
        authorization: Cookies.get("_auth"),
      },
    });
    
    return true;
  } catch (error) {
    console.error("Failed to delete attachment:", error);
    return { error: "Impossibile rimuovere l'allegato. Riprova più tardi." };
  }
};

/**
 * API function to upload submitted attachments
 * POST api/attachments/{penalty_id}
 * BODY: formdata -> attachment_{type}: file
 */
export const uploadSubmittedAttachment = async (
  penaltyId: string, 
  files: { file: File, type: string }[]
) => {
  if (!files.length) {
    return { error: "No files to upload" };
  }

  const formData = new FormData();
  
  // Add all files to formData with the correct naming convention
  files.forEach(item => {
    formData.append(`attachment_${item.type}`, item.file);
  });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/attachments/${penaltyId}`,
      formData,
      {
        headers: {
          authorization: Cookies.get("_auth"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    
    return true;
  } catch (error) {
    console.error("Failed to upload attachments:", error);
    return { error: "Impossibile caricare gli allegati. Riprova più tardi." };
  }
};

export const getRequiredDocuments = (
  category: PenaltyCategory | null
): PenaltyAttachmentType[] => {
  switch (category) {
    case "EMPTY_VEHICLE":
      return [
        { value: "FDC", required: true },
        { value: "OTHER", required: false },
      ];
    case "TRAVEL_TIME":
      return [
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: true },
        { value: "OTHER", required: false },
      ];
    case "HUB_DELAY":
      return [
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: true },
        { value: "FDC", required: false },
        { value: "OTHER", required: false },
      ];
    case "TRAFFIC":
      return [
        { value: "TRAFFIC_EVIDENCE", required: true },
        { value: "GPS", required: true },
        { value: "EMAIL_SENNDER", required: false },
        { value: "GPS_GRAPH", required: false },

        { value: "OTHER", required: false },
      ];
    case "FERRY_DELAY":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "SHIPOWNER_COMMUNICATION", required: true },
        { value: "GPS", required: true },
        { value: "OTHER", required: false },
      ];
    case "VEHICLE_ACCIDENT":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "POLICE_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "POLICE_STOP":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "POLICE_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "HUB_OPENING":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "OTHER", required: false },
      ];

    case "BROKEN_VEHICLE":
      return [
        { value: "EMAIL_SENNDER", required: true },
        { value: "GPS", required: true },
        { value: "REPAIR_REPORT", required: true },
        { value: "OTHER", required: false },
      ];
    case "WRONG_DOCUMENT":
      return [
        { value: "GPS", required: true },
        { value: "FDC", required: true },
        { value: "OTHER", required: false },
      ];
    case "OTHER":
      return [{ value: "OTHER", required: true }];
    default:
      return [{ value: "OTHER", required: true }];
  }
};
