import { Button, Checkbox, Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useState } from "react";
const ModalTitle = (
  <>
    <WarningFilled style={{ marginRight: "8px", color: "#f57c00" }} />
    Attenzione
  </>
);

const PenaltiesSafetyInfoModal = (props: { onOk: any; open: any }) => {
  const [isConfirmChecked, setIsConfirmChecked] = useState(false);
  const [noShow, setNoShow] = useState(true);
  const handleConfirmChange = (e: any) => {
    setIsConfirmChecked(e.target.checked);
  };

  const handleNoShowChange = (e: any) => {
    setNoShow(e.target.checked);
  };

  return (
    <Modal
      title={ModalTitle}
      open={props.open}
      okButtonProps={{ disabled: !isConfirmChecked }}
      closable={false}
      footer={[
        <Button
          type="primary"
          disabled={!isConfirmChecked}
          onClick={() => props.onOk(noShow)}
        >
          OK
        </Button>,
      ]}
    >
      <p>
        Si comunica che, a decorrere dal 1° aprile 2025, in caso di mancato
        rispetto dei requisiti tecnici e operativi obbligatori per l'esecuzione
        del servizio di trasporto, verranno applicate le misure previste dagli
        artt. 6.7 e 6.10 dei nostri Termini e Condizioni Generali o, per i
        trasportatori che avranno sottoscritto il contratto quadro, dalle
        corrispondenti disposizioni contenute in tale accordo. Si precisa,
        inoltre, che eventuali difformità rilevate saranno oggetto di verifica
        preliminare attraverso il controllo del Foglio di Corsa, al fine di
        garantire l’accuratezza delle segnalazioni relative al mancato rispetto
        dei suddetti requisiti, riscontrato presso le strutture del committente.
        Per qualsiasi chiarimento o necessità di approfondimento, vi invitiamo a
        contattare il nostro servizio di supporto all’indirizzo email indicato
        nella sezione contatti.
      </p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Checkbox onChange={handleConfirmChange}>
          Conferma presa visione
        </Checkbox>
        <Checkbox onChange={handleNoShowChange} checked={noShow}>
          Non mostrare più
        </Checkbox>
      </div>
    </Modal>
  );
};

export default PenaltiesSafetyInfoModal;
