import { Button, Modal } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import SubmittedAttachmentCell from "./SubmittedAttachmentsCell";

import {
  getRequiredDocuments,
  IPenaltyBeforeUpload,
} from "../../services/penalty";
import { useEffect, useState } from "react";

const SubmittedAttachmentsModal = (props: {
  onOk: any;
  onClose: any;
  onReset: any;
  open: any;
  data: IPenaltyBeforeUpload;
  onFileUpload: any;
  onFileRemove: any;
}) => {
  const [attachments, setAttachments] = useState<any>([]);
  const [warningModal, setWarningModal] = useState(false);
  useEffect(() => {
    setAttachments(props.data?.justification?.attachments_url || []);
  }, [props.data]);

  const ModalTitle = (
    <>
      <FileAddOutlined style={{ marginRight: "8px", color: "green" }} />
      Carica Allegati - {props?.data?.sennder_reference}(
      {props?.data?.tour_composition})
    </>
  );
  const justificationCategory = props.data?.justification?.category || null;
  const requiredAttachments = getRequiredDocuments(justificationCategory);

  const handleWarningModalProceed = () => {
    setWarningModal(false);
    props.onReset();
  };

  const handleWarningModalOk = () => {
    setWarningModal(false);
  };

  const handleModalClose = () => {
    props.onClose();
  };

  return (
    <>
      <Modal
        open={warningModal}
        width={700}
        title="Attenzione!"
        closable={false}
        footer={[
          <Button
            key="proceed"
            type="text"
            onClick={handleWarningModalProceed}
            style={{ color: "red" }}
          >
            Si, procedi
          </Button>,

          <Button key="upload" type="primary" onClick={handleWarningModalOk}>
            Torna indietro
          </Button>,
        ]}
      >
        <>
          <p className="ant-upload-text">
            I documenti caricati, se non confermati verranno cancellati, sei
            sicuro di voler procedere?
          </p>
        </>
      </Modal>

      <Modal
        width={700}
        title={ModalTitle}
        open={props.open}
        closable={true}
        onCancel={handleModalClose}
        footer={[<></>]}
      >
        <>
          <SubmittedAttachmentCell
            data={attachments}
            requiredAttachments={requiredAttachments}
            onUpload={(penaltyId: string) =>
              props.onFileUpload(props.data._id, penaltyId)
            }
            onRemove={(value: string) =>
              props.onFileRemove(props.data._id, value)
            }
            penaltyId={props.data?._id || ""}
            status={props.data?.status as string}
          />
        </>
      </Modal>
    </>
  );
};

export default SubmittedAttachmentsModal;
