import { useEffect, useRef, useState } from "react";
import { FloatButton, Tooltip, notification } from "antd";
import { IPenalty, getSubmitted, uploadAttachments } from "../services/penalty";
import { SendOutlined } from "@ant-design/icons";
import RecapTiles from "../components/RecapTiles";
import SubmittedPenaltiesTable from "../components/SubmittedPenaltiesTable";
import { saveAs } from "file-saver";
import { formatDate, mapStatus } from "../common/Utils";
import Cookies from "js-cookie";
import SubmittedAttachmentsModal from "../components/modals/SubmittedAttachmentsModal";
import ConfirmPenalitesModal from "../components/modals/ConfirmPenaltiesModal";
import * as XLSX from "xlsx";
import FiltersDrawer from "../components/FiltersDrawer";
import { PenaltyFilters } from "../common/interfaces";
export interface SubmittedPenalty extends IPenalty {
  attachmentsList?: any[];
}

function SubmittedPenaltiesPage() {
  const carrier = JSON.parse(Cookies.get("_auth_state") as string);
  let stats = useRef({
    carrier: carrier.carrier_name,
    penalties: { total: 0, to_review: 0, applied: 0, not_applied: 0 },
  });

  const [penalties, setPenalties] = useState<SubmittedPenalty[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentsModalPenalty, setAttachmentsModalPenalty] =
    useState<SubmittedPenalty | null>(null);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [filters, setFilters] = useState<PenaltyFilters>({});
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  function downloadXlsx() {
    const jsonXlsx = penalties.map((penalty: SubmittedPenalty) => {
      return {
        Referenza: penalty.sennder_reference,
        "Codice Linea": penalty.line_code,
        Competenza: penalty.tour_composition,
        Ritardo: `${penalty.delay_minutes} min`,
        "Tipo Ritardo": penalty.delay_type,
        "Penale %": `${penalty.penalty_percentage * 100} %`,
        "Data Caricamento": penalty.received_on
          ? formatDate(penalty.received_on)
          : "-",
        "Data Scadenza": penalty.expiration_date
          ? formatDate(penalty.expiration_date)
          : "-",
        Giustificazione: penalty.justification?.body || "-",
        Stato: penalty.status ? mapStatus(penalty.status) : "-",
        "Motivo Rifiuto": penalty.processing_body || "-",
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(jsonXlsx);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Penalties");
    const xlsxBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([xlsxBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "penalties.xlsx");
  }

  async function retrivePenalties(queryFilters: PenaltyFilters) {
    try {
      setLoading(true);
      const penaltiesResponse = await getSubmitted(queryFilters);
      const data = penaltiesResponse.data;
      setPenalties(data);
      return data;
    } catch (error: any) {
      notification.error({ message: "Errore", description: error.message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    retrivePenalties({}).then((data) => {
      stats.current.penalties = {
        total: data.length,
        to_review: data.filter((p: any) => p.status === "TO_REVIEW").length,
        applied: data.filter((p: any) => p.status === "APPLIED").length,
        not_applied: data.filter((p: any) => p.status === "NOT_APPLIED").length,
      };
    });
  }, []);

  useEffect(() => {
    if(!isAttachmentsModalOpen) {
      refetchPenalties()
    }
  }, [isAttachmentsModalOpen]);

  const refetchPenalties = async () => {
    try {
      setLoading(true);
      const response = await getSubmitted({ });
      
      if (response.data && response.data.length > 0) {
        setPenalties(response.data);
      }
    } catch (error) {
      console.error("Failed to refresh penalty data:", error);
    } finally {
      setLoading(false);
    }
  }

  // Update the existing handlers to use refetchPenalty
  function handleFileRemove(id: string, fileUid: any) {
    // After removing a file locally, also refetch the penalty data
    //refetchPenalty(id);
    
    // Keep the existing logic to update local state immediately for UI responsiveness
    const updatedPenalties = penalties.map((penalty: SubmittedPenalty) => {
      if (penalty._id === id) {
        const result = {
          ...penalty,
          attachmentsList: penalty.attachmentsList?.filter(
            (item: any) => item.uid !== fileUid
          ),
        };
        return result;
      }
      return penalty;
    });

    setPenalties(updatedPenalties);
  }

  function handleFileUpload(id: string, fileList: any[]) {
    // After uploading, refetch the penalty data
    //refetchPenalty(id);
    
    // Keep the existing logic for UI responsiveness
    const updatedPenalties = penalties.map((penalty: SubmittedPenalty) => {
      if (penalty._id === id) {
        const attachments = penalty.attachmentsList
          ? [...penalty.attachmentsList, ...fileList]
          : [...fileList];

        const result = {
          ...penalty,
          attachmentsList: removeAttachmentsDuplicate(attachments),
        };
        return result;
      }

      return penalty;
    });

    setPenalties(updatedPenalties);
  }

  // Replace the existing handleAttachmentsModalOk with this updated version
  const handleAttachmentsModalOk = async () => {
    // Refresh the penalty data to ensure we have up-to-date attachment information
    /* if (attachmentsModalPenalty) {
      await refetchPenalty(attachmentsModalPenalty._id);
    }
    
    setAttachmentsModalPenalty(null); */
    setIsAttachmentsModalOpen(false);
  };

  const handleAttachmentsModalReset = () => {
    setAttachmentsModalPenalty((prevPenalty: any) => {
      return {
        ...prevPenalty,
        attachmentsList: [],
      };
    });
    setPenalties((prevPenalties) =>
      prevPenalties.map((penalty) => {
        if (penalty._id === attachmentsModalPenalty?._id) {
          return {
            ...penalty,
            attachmentsList: [],
          };
        }
        return penalty;
      })
    );
    setIsAttachmentsModalOpen(false);
  };

  const handleAttachmentsButtonClick = (id: string) => {
    const penalty = penalties.find(
      (penalty: SubmittedPenalty) => penalty._id === id
    )!;
    setAttachmentsModalPenalty(penalty);
    setIsAttachmentsModalOpen(true);
  };

  function removeAttachmentsDuplicate(attachments: any) {
    return attachments.reduce((acc: any, current: any) => {
      const existingItem = acc.find((item: any) => item.uid === current.uid);

      if (!existingItem) {
        return [...acc, current];
      } else {
        if (current.status === "done") {
          return acc.map((item: any) =>
            item.uid === current.uid ? current : item
          );
        }
        return acc;
      }
    }, []);
  }

  const handleConfirmButton = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmModalOk = () => {
    setIsConfirmModalOpen(false);
    confirmPenalties();
  };

  const handleConfirmModalCancel = () => {
    setIsConfirmModalOpen(false);
  };

  function handleTilesClick(status: string) {
    const newFilters = filters;
    if (status === "") delete newFilters["status"];
    else newFilters.status = status;
    handleFiltersChange(newFilters);
  }

  async function handleFiltersChange(filters: any) {
    setIsFilterSidebarOpen(false);
    setFilters(filters);
    await retrivePenalties(filters);
  }

  function handleFilterRemove(filter: keyof PenaltyFilters) {
    const { [filter]: _, ...newFilters } = filters;
    handleFiltersChange(newFilters);
  }

  async function uploadPenaltyAttachments(penalty: SubmittedPenalty) {
    try {
      notification.info({
        message: `CARICAMENTO ALLEGATI ${penalty.sennder_reference} (${penalty.route_composition}) IN CORSO`,
      });
      setLoading(true);

      const uploadResponse = await uploadAttachments(
        penalty._id,
        penalty.attachmentsList
      );
      notification.success({
        message: `${penalty.sennder_reference} (${penalty.route_composition}) - Allegati caricati`,
      });
      const foundPenalty = penalties.find((p) => p._id === penalty._id);
      if (foundPenalty) {
        if (!foundPenalty.justification!.attachments_url)
          foundPenalty.justification!.attachments_url = [];
        foundPenalty.justification!.attachments_url.push(
          uploadResponse.data.url
        );
        foundPenalty.attachmentsList = [];
      }
    } catch (error: any) {
      notification.error({
        message: `${penalty.sennder_reference} (${penalty.route_composition}) - Allegati non caricati: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  }

  async function confirmPenalties() {
    const penaltiesToUpload = penalties.filter(
      (p) => p.attachmentsList?.length
    );
    if (!penaltiesToUpload.length) {
      return notification.warning({
        message: "Nessuna penale modificata",
      });
    }
    for (let penalty of penaltiesToUpload) {
      await uploadPenaltyAttachments(penalty);
    }
  }

  return (
    <div className="content-wrapper">
      <FiltersDrawer
        key={filters.toString()}
        filters={filters}
        onClose={() => setIsFilterSidebarOpen(false)}
        open={isFilterSidebarOpen}
        onFilterConfirm={handleFiltersChange}
        availableFilters={[
          "line_code",
          "expiration_date",
          "order_date",
          "upload_date",
          "status",
        ]}
      />

      <SubmittedAttachmentsModal
        onClose={handleAttachmentsModalOk}
        open={isAttachmentsModalOpen}
        onOk={handleAttachmentsModalOk}
        onReset={handleAttachmentsModalReset}
        onFileRemove={handleFileRemove}
        onFileUpload={handleFileUpload}
        data={attachmentsModalPenalty as SubmittedPenalty}
      />
      <RecapTiles
        isLoading={loading}
        data={stats.current}
        onTilesClick={handleTilesClick}
      />

      <SubmittedPenaltiesTable
        data={penalties}
        loading={loading}
        onUploadButtonClick={handleAttachmentsButtonClick}
        download={downloadXlsx}
        onFilterClick={() => setIsFilterSidebarOpen(true)}
        filters={filters}
        onFilterRemove={handleFilterRemove}
      />

      <Tooltip placement="top" title="Invia le penali aggiornate">
        <FloatButton
          icon={<SendOutlined />}
          type="primary"
          target="_blank"
          style={{ right: 24, width: 150 }}
          onClick={handleConfirmButton}
          description="Aggiorna penali"
          shape="square"
        />
      </Tooltip>

      <ConfirmPenalitesModal
        open={isConfirmModalOpen}
        onOk={handleConfirmModalOk}
        onCancel={handleConfirmModalCancel}
        text="Vuoi confermare l'invio delle penali aggiornate?"
      />
    </div>
  );
}

export default SubmittedPenaltiesPage;
