import {
  UploadOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, message, Tooltip, Upload, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  PenaltyAttachmentLabel,
  PenaltyAttachmentType,
  PenaltyAttachmentTypeValue,
  deleteSubmittedAttachment,
  uploadSubmittedAttachment,
} from "../../services/penalty";

// Define the structure of submitted file items
interface SubmittedFileItem {
  _id: string;
  url: string;
  file_type: string;
  exportable: boolean;
  file_id?: string;
}

// Expanded interface for uploaded file objects
interface UploadedFileItem {
  file: {
    uid: string;
    name?: string;
    status?: string;
    originFileObj?: File;
    [key: string]: any; // Allow for other properties
  };
  attachment_type: PenaltyAttachmentTypeValue;
  confirmed: boolean;
}

// Fixed interface for the component props
interface SubmittedAttachmentCellProps {
  data: (SubmittedFileItem | UploadedFileItem)[];
  requiredAttachments: PenaltyAttachmentType[];
  onUpload: (value: any) => void;
  onRemove: (value: any) => void;
  penaltyId?: string;
  status: string;
}

const SubmittedAttachmentCell = (
  props: SubmittedAttachmentCellProps
): JSX.Element => {
  const [fileList, setFileList] = useState<
    (SubmittedFileItem | UploadedFileItem)[]
  >([]);
  const [uploadQueue, setUploadQueue] = useState<
    { file: File; type: string }[]
  >([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setFileList(props.data || []);
  }, [props.data]);

  // Helper to extract filename from URL
  const getFilenameFromUrl = (url: string) => {
    try {
      // Check if it's a SharePoint-like URL with file parameter
      if (url.includes("file=")) {
        // Extract the file parameter value
        const fileMatch = url.match(/[?&]file=([^&]+)/);
        if (fileMatch && fileMatch[1]) {
          // Decode the URL-encoded parameter
          return decodeURIComponent(fileMatch[1]);
        }
      }

      // Fall back to the original method for simple URLs
      const parts = url.split("/");
      return parts[parts.length - 1];
    } catch (e) {
      return "Attached file";
    }
  };

  // Helper to determine if an item is a submitted file
  const isSubmittedFile = (file: any): file is SubmittedFileItem => {
    return file && "_id" in file && "file_type" in file && "url" in file;
  };

  const isOtherUnique = useMemo(() => {
    return (
      props.requiredAttachments.length === 1 &&
      props.requiredAttachments[0].value === "OTHER"
    );
  }, [props.requiredAttachments]);

  const isOtherUnlocked = useMemo(() => {
    const requiredAttachments = isOtherUnique
      ? props.requiredAttachments
      : props.requiredAttachments.filter((doc) => doc.value !== "OTHER");

    return requiredAttachments.every((requiredDoc: PenaltyAttachmentType) =>
      fileList.some(
        (file) =>
          (isSubmittedFile(file) && file.file_type === requiredDoc.value) ||
          (!isSubmittedFile(file) && file.attachment_type === requiredDoc.value)
      )
    );
  }, [fileList, props.requiredAttachments, isOtherUnique]);

  // Handle file deletion with confirmation
  const handleSubmittedFileDelete = (
    file: SubmittedFileItem | UploadedFileItem
  ) => {
    Modal.confirm({
      title: "Conferma rimozione",
      content: "Sei sicuro di voler rimuovere questo allegato?",
      okText: "Sì",
      cancelText: "No",
      onOk: async () => {
        if (isSubmittedFile(file)) {
          const result = await deleteSubmittedAttachment(file._id);
          if (result === true) {
            const updatedFileList = fileList.filter((f) =>
              isSubmittedFile(f) ? f._id !== file._id : true
            );
            setFileList(updatedFileList);
            // Pass the penalty ID to trigger a refetch
            props.onRemove(props.penaltyId);
            message.success("Allegato rimosso con successo");
          } else if (result.error) {
            message.error(result.error);
          }
        } else {
          // Handle removal from upload queue for newly added files
          setFileList(
            fileList.filter((f) =>
              !isSubmittedFile(f) ? f.file.uid !== file.file.uid : true
            )
          );

          // Use uploadQueue from component state
          setUploadQueue(
            uploadQueue.filter((item) => {
              // We can identify files by comparing uids if available or fall back to matching the file object
              if (
                file.file.originFileObj &&
                item.file === file.file.originFileObj
              ) {
                return false;
              }
              // Fall back to matching file names when available
              if (file.file.name && item.file.name === file.file.name) {
                return false;
              }
              return true;
            })
          );

          props.onRemove(file.file.uid);
        }
      },
    });
  };

  // Handle file uploads with confirmation
  const handleSubmittedFileUpload = async () => {
    if (!props.penaltyId) {
      message.error("Impossibile caricare gli allegati.");
      return;
    }

    if (uploadQueue.length === 0) {
      return;
    }

    setIsUploading(true);

    try {
      const result = await uploadSubmittedAttachment(
        props.penaltyId,
        uploadQueue
      );
      if (result === true) {
        setUploadQueue([]);
        message.success("Allegati caricati con successo");
        props.onUpload(props.penaltyId);
      } else if (result && result.error) {
        message.error(result.error || "Errore durante il caricamento");
      } else {
        message.error("Risposta non valida dal server");
      }
    } catch (error) {
      console.error("Error during attachment upload:", error);
      message.error("Si è verificato un errore durante il caricamento");
    } finally {
      setIsUploading(false);
    }
  };

  // Handle file selection
  const onSubmittedFileChange = (
    info: any,
    type: PenaltyAttachmentTypeValue
  ) => {
    const { status, name, size, uid, originFileObj } = info.file;
    const fileAlreadyExists = fileList.some(
      (f) => !isSubmittedFile(f) && f.file.uid === uid
    );

    if (fileAlreadyExists) return;

    if (fileList.length === 5)
      return message.error(`Impossibile allegare più di 5 documenti`);

    const fileMb = Number((size / 1024 / 1024).toFixed(2));
    if (fileMb >= 5)
      return message.error(
        `${name} supera la dimensione massima concessa ${fileMb} MB / 5 MB`
      );

    if (status === "error") {
      return message.error(`${name} non può essere caricato.`);
    }

    // Add to file list for UI display
    setFileList((prev) => [
      ...prev,
      {
        file: { ...info.file, status: "done" },
        attachment_type: type,
        confirmed: false,
      },
    ]);

    // Add to upload queue for when user confirms
    setUploadQueue((prev) => [...prev, { file: originFileObj, type }]);

    message.info(
      `${name} (${PenaltyAttachmentLabel[type]}) pronto per essere caricato. Conferma per procedere.`
    );
  };

  // Updated helper to preview a file
  const previewFile = (file: SubmittedFileItem | File) => {
    let url: string;
    if ("file_id" in file) {
      // For submitted files with an ID, use the backend download URL
      url = `${process.env.REACT_APP_API_URL}/attachments/${file.file_id}`;
    } else {
      // For local files, create a blob URL
      url = URL.createObjectURL(file as File);
    }
    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
        justifyContent: "flex-start",
        alignContent: "flex-start",
      }}
    >
      {props.requiredAttachments.map((attachment: PenaltyAttachmentType) => {
        // Filter files for this attachment type
        const attachmentFiles = fileList.filter(
          (file) =>
            (isSubmittedFile(file) && file.file_type === attachment.value) ||
            (!isSubmittedFile(file) &&
              file.attachment_type === attachment.value)
        );

        return (
          <div
            style={{
              width: "100%",
              height: "48%",
              display: "flex",
              gap: "10px",
              marginTop: "20px",
            }}
            key={attachment.value + attachment.required}
          >
            <Upload
              onChange={(e: any) => onSubmittedFileChange(e, attachment.value)}
              fileList={[]}
              disabled={
                attachment.value === "OTHER" &&
                !isOtherUnique &&
                !isOtherUnlocked
              }
            >
              {attachment.value === "OTHER" &&
              !isOtherUnique &&
              !isOtherUnlocked ? (
                <Tooltip title="Carica tutti gli allegati per poter caricare 'Altro' ">
                  <Button icon={<UploadOutlined />} disabled>
                    Carica
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  icon={<UploadOutlined />}
                  disabled={props.status !== "TO_REVIEW"}
                >
                  Carica
                </Button>
              )}
            </Upload>
            <div style={{ width: "100%" }}>
              <b>
                {PenaltyAttachmentLabel[attachment.value]} (
                {attachment.required ? "Necessario" : "A supporto"})
              </b>
              {!attachmentFiles.length && (
                <>
                  <br />
                  <i style={{ color: "#808080" }}>Carica uno o più allegati</i>
                </>
              )}

              {/* Display files with preview and delete buttons */}
              {attachmentFiles.map((file) => {
                if (isSubmittedFile(file)) {
                  const filename = getFilenameFromUrl(file.url);
                  return (
                    <div
                      key={file._id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "4px 0",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "70%",
                        }}
                      >
                        <PaperClipOutlined
                          style={{ color: "#f57c00", marginRight: "8px" }}
                        />
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {filename}
                        </span>
                      </span>
                      <span style={{ display: "flex", gap: "8px" }}>
                        <Button
                          size="small"
                          icon={<EyeOutlined />}
                          onClick={() => previewFile(file)}
                          title="Preview"
                          style={{ fontSize: "14px" }}
                          disabled={!file.file_id}
                        />
                        <Button
                          size="small"
                          icon={<DeleteOutlined />}
                          onClick={() => handleSubmittedFileDelete(file)}
                          danger
                          title="Delete"
                          style={{ fontSize: "14px" }}
                          disabled={props.status !== "TO_REVIEW"}
                        />
                      </span>
                    </div>
                  );
                } else {
                  // Handle uploaded files
                  const isPendingUpload = uploadQueue.some(
                    (item) =>
                      item.file === file.file.originFileObj ||
                      (file.file.name && item.file.name === file.file.name)
                  );

                  return (
                    <div
                      key={file.file.uid}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "4px 0",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "70%",
                        }}
                      >
                        <PaperClipOutlined
                          style={{ color: "#f57c00", marginRight: "8px" }}
                        />
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {file.file.name || `File-${file.file.uid}`}
                        </span>
                      </span>
                      <span style={{ display: "flex", gap: "8px" }}>
                        <Tooltip
                          title={
                            isPendingUpload
                              ? "Disponibile dopo il caricamento"
                              : "Anteprima"
                          }
                        >
                          <Button
                            size="small"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              if (file.file.originFileObj) {
                                previewFile(file.file.originFileObj);
                              }
                            }}
                            title="Preview"
                            style={{ fontSize: "14px" }}
                            disabled={
                              isPendingUpload || !file.file.originFileObj
                            }
                          />
                        </Tooltip>
                        <Button
                          size="small"
                          icon={<DeleteOutlined />}
                          onClick={() => handleSubmittedFileDelete(file)}
                          danger
                          title="Delete"
                          style={{ fontSize: "14px" }}
                        />
                      </span>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        );
      })}

      {/* Upload confirmation button */}
      {uploadQueue.length > 0 && (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="primary"
            onClick={handleSubmittedFileUpload}
            loading={isUploading}
          >
            Conferma caricamento ({uploadQueue.length}{" "}
            {uploadQueue.length === 1 ? "file" : "files"})
          </Button>
        </div>
      )}
    </div>
  );
};

export default SubmittedAttachmentCell;
